body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2ece5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.attendee-category {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.attendee-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 0.4rem;
}

.ticket-wrapper {
  display: 'flex';
  border: '1px solid #efefef';
  margin-bottom: 15;
}

.p-default {
  all: unset;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}
.content-wrapper p {
  all: unset;
}

.home-left-side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ticket-box {
  display: flex;
  flex-direction: row;
  border: '1px solid #efefef';
  margin-bottom: 15;
}
table,
tr,
td {
  border: 1px solid #efefef !important;
  font-size: 13px;
}
table {
  width: 100%;
}

td {
  width: fit-content !important;
}

td {
  padding: 0.8rem;
}

.ticket-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.creditcard-flag {
  width: 24px;
  height: 19px;
}

.creditcard-number-wrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

input:focus,
select:focus {
  border-color: #092b49 !important;
}

/* .col-md-6 {
  margin-top: 7px !important;
} */

.qr-code {
  width: 200px;
}

.qr-code-wrapper {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-response {
  width: 163px !important;
}

.transaction-response {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
}

.grecaptcha-badge {
  display: none !important;
}

.message-wrapper {
  margin: 2.4rem;
}
.p-wrapper {
  margin-top: 1rem;
}

.btn-submit:hover {
  background-color: #1faa74 !important;
}
.my-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
}

.my-alert {
  max-width: 350px;
  position: relative;
  top: 10;
  left: 0;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 10px;
  line-height: 1.8;
  border-radius: 5px;
  cursor: hand;
  cursor: pointer;
  font-family: sans-serif;
  font-weight: 400;
}
.my-alert-p {
  text-align: justify !important;
  margin: 0 0 1rem 0;
}
.my-alert h3 {
  margin-bottom: 1rem;
}
.my-alertCheckbox {
  display: none;
}

:checked + .alert {
  display: none;
}

.my-alertText {
  display: table;
  margin: 0 auto;
  text-align: justify;
  font-size: 16px;
}

.my-alertClose {
  float: right;
  padding-top: 5px;
  font-size: 10px;
}

.my-clear {
  clear: both;
}

.my-info {
  background-color: #eee;
  border: 1px solid #ddd;
  color: #999;
}

.my-success {
  background-color: #efe;
  border: 1px solid #ded;
  color: #9a9;
}

.my-notice {
  background-color: #eff;
  border: 1px solid #dee;
  color: #9aa;
}

.my-warning {
  background-color: #ffffff;
  border: 1px solid #f1b44c;
  color: #f1b44c;
}

.my-error {
  background-color: #ffffff;
  border: 1px solid #f46a6a;
  color: #f46a6a;
}

.card-body {
  background-color: #f2ece5 !important;
}
.card-payment {
  background-color: #f2ece5 !important;
}

table,
th,
td {
  border: 1px solid #142433 !important;
}

.description p {
  text-align: left !important;
}

.btn-home {
  width: 150px;
  height: 80px;
  border: none;
  color: white;
  background-color: #142433;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-home:hover {
  background-color: #1faa74;
  color: white;
}

.info-payment {
  text-align: center;
  font-size: 0.6rem;
}
