.div-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

.header-logo {
  width: 100%;
  background-color: #092b49;
  display: flex;
  justify-content: center;
}

.img-logo {
  width: 200px;
  padding: 20px 0;
}

.div-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-image: url(../../assets/images/bannerCarrinhoBorrado.png); */
  background-size: 100% 100%;
}

.header-img {
  width: 800px;
  height: 400px;
  margin: -20px 0 -20px 0;
  border-radius: 10px;
  /* box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%); */
  box-shadow: 0px 5px 10px black;
}

@media (max-width: 800px) {
  .header-img {
    width: 100%;
    height: auto;
  }

  .header-logo {
    /* display: flex;
    justify-content: center; */
  }

  .img-logo {
    padding: 20px 0;
  }
}
